import './PokéFooter.scss'
import React from "react";

function PokéFooter() {
    return (
        <div className="footer">
            <p>PokéDex React App</p>
        </div>
    );
}

export default PokéFooter;
